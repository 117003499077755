import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react'
import isEqual from 'lodash.isequal'

import { formatPrice } from '../utils/format-price'

import Layout from '../components/layout'
import Seo from '../components/seo'
import AddToCart from '../components/template-parts/add-to-cart'
import Suggestions from '../components/template-parts/suggestions'
import { StoreContext } from '../context/store-context'

import '../scss/product.scss'


const ProductPageTemplate = ({ data: { product, suggestions } }) => {

  const {
    options,
    variants,
    variants: [initialVariant],
    priceRangeV2,
    title,
    description,
    images
  } = product

  const { client } = React.useContext(StoreContext)

  const [variant, setVariant] = React.useState({ ...initialVariant })

  const productVariant =
    client.product.helpers.variantForOptions(product, variant) || variant

  const [available, setAvailable] = React.useState(
    productVariant.availableForSale
  )

  const checkAvailablity = React.useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct) => {
        const result =
          fetchedProduct?.variants.filter(
            (variant) => variant.id === productVariant.storefrontId
          ) ?? []

        if (result.length > 0) {
          setAvailable(result[0].available)
        }
      })
    },
    [productVariant.storefrontId, client.product]
  )

  const handleOptionChange = (index, value) => {
    console.log('option change?')
    if (value === "") {
      return
    }

    const currentOptions = [...variant.selectedOptions]

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    }

    const selectedVariant = variants.find((variant) => {
      return isEqual(currentOptions, variant.selectedOptions)
    })

    setVariant({ ...selectedVariant })
  }

  React.useEffect(() => {
    checkAvailablity(product.storefrontId)
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId])

  const price = formatPrice(
    priceRangeV2.minVariantPrice.currencyCode,
    variant.price
  )

  const originalPrice = (variant.compareAtPrice) ? variant.compareAtPrice : 0;
  const save = originalPrice - variant.price

  const hasVariants = variants.length > 1
  const hasImages = images.length > 0

  return (
    <Layout isProductPage={true}>
      <Seo title={title} description={description} />
      <main className="product-wrapper">
        <div className="container">
          <div className="product-row">
            {hasImages && (
              <div className="images">
                  {originalPrice ? (<div className="save">Save {formatPrice('USD', save)}</div>) : ''}
                  {images && (
                    <Swiper modules={[Navigation]} spaceBetween={50} autoHeight={true} slidesPerView={1} navigation>
                      {images.map((image, index) => (
                        <SwiperSlide key={`product-image-${image.id}`}>
                          <GatsbyImage
                            objectFit="contain"
                            loading={index === 0 ? "eager" : "lazy"}
                            alt={
                              image.altText
                                ? image.altText
                                : `Product Image of ${title} #${index + 1}`
                            }
                            image={image.gatsbyImageData}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  )}
              </div>
            )}

            <div className="details">
              <h1>
                {title}
              </h1>
              <h2>
                <span>{originalPrice ? (<s>{formatPrice('USD', originalPrice)}</s>) : ''} {price}</span>
              </h2>
              <p>
                {description}
              </p>
              
              {hasVariants &&
                options.map(({ id, name, values }, index) => (
                  <div className="option" key={id}>
                    <h4>
                      {name}
                    </h4>
                    <div className="options-row">
                      {values.map((value) => {
                        const isMultiVariable = productVariant.title.split(' / ')

                        return (
                          <button key={`${name}-${value}`} className={productVariant.title === value || isMultiVariable?.includes(value) ? 'button active' : 'button'} onClick={() => handleOptionChange(index, value)}>
                            {value}
                          </button>
                        )
                      })}
                    </div>
                  </div>
                ))}

              <AddToCart variantId={productVariant.storefrontId} available={available} />

            </div>
          </div>
        </div>
      </main>
      
      <Suggestions products={suggestions.products} />
      
    </Layout>
  )
}

export default ProductPageTemplate


export const pageQuery = graphql`
  query($id: String!, $productType: String!) {
    product: shopifyProduct(id: { eq: $id }) {
      title
      description
      productType
      tags
      priceRangeV2 {
        maxVariantPrice {
          amount
          currencyCode
        }
        minVariantPrice {
          amount
          currencyCode
        }
      }
      storefrontId
      images {
        altText
        id
        gatsbyImageData(layout: FULL_WIDTH)
      }
      variants {
        availableForSale
        storefrontId
        title
        price
        compareAtPrice
        selectedOptions {
          name
          value
        }
      }
      options {
        name
        values
        id
      }
    }
    suggestions: allShopifyProduct(
      limit: 3
      filter: { productType: { eq: $productType }, id: { ne: $id } }
    ) {
      products: nodes {
        id
        title
        handle
        images {
          id
          altText
          gatsbyImageData(aspectRatio: 1, width: 640)
        }
        priceRangeV2 {
          minVariantPrice {
            amount
            currencyCode
          }
        }
        variants {
          compareAtPrice
        }
        vendor
      }
    }
  }
`
