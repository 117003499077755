import React, { useContext, useState } from "react"
import { StoreContext } from "../../context/store-context"

import '../../scss/add-to-cart.scss'

const AddToCart = ({ variantId, available }) => {

    const { addVariantToCart, loading } = useContext(StoreContext)
    const [quantity, setQuantity] = useState(1)

    const addToCart = (e) => {
        e.preventDefault()
        addVariantToCart(variantId, quantity)
    }

    const handleQuantityChange = (e) => {
      setQuantity(e.target.value)
    }

    const increment = () => {
      setQuantity(quantity + 1)
    }

    const decrement = () => {
      if (quantity > 1) {
        setQuantity(quantity - 1)
      }
    }

    return (
      <div className="add-to-cart">
        <div className="quantity">
          <button onClick={decrement}>
            -
          </button>
          <input type="numeric" value={quantity} onChange={(e) => handleQuantityChange(e)} />
          <button onClick={increment}>
            +
          </button>
        </div>
        <div className="button">
          <button type="submit" className={available ? 'available' : 'disabled'} onClick={(e) => addToCart(e)} disabled={!available || loading}>
            {available ? "Add to Cart" : "Out of Stock"}
          </button>
        </div>
      </div>
    )
}

export default AddToCart
